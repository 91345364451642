import { Attribute, Directive, ElementRef, Inject } from '@angular/core';

import { Observable } from 'rxjs';

import { ResizeObserverService } from '@core/observers/resize-observer/services/resize-observer.service';
import { RESIZE_OPTION_BOX, RESIZE_OPTION_BOX_DEFAULT } from '@core/observers/resize-observer/tokens/resize-option-box';

// TODO switch to Attribute once https://github.com/angular/angular/issues/36479 is fixed
export function boxExtractor({ nativeElement }: ElementRef<Element>): ResizeObserverBoxOptions {
  const attribute = nativeElement.getAttribute('tessResizeBox') as ResizeObserverBoxOptions;

  return boxFactory(attribute);
}

export function boxFactory(box: ResizeObserverBoxOptions | null): ResizeObserverBoxOptions {
  return box || RESIZE_OPTION_BOX_DEFAULT;
}

@Directive({
  standalone: true,
  selector: '[tessResizeObserver]',
  outputs: ['tessResizeObserver'],
  providers: [
    ResizeObserverService,
    {
      provide: RESIZE_OPTION_BOX,
      deps: [ElementRef],
      useFactory: boxExtractor,
    },
  ],
})
export class ResizeObserverDirective {
  constructor(
    @Inject(ResizeObserverService)
    readonly tessResizeObserver: Observable<ResizeObserverEntry[]>,
    @Attribute('tessResizeBox') _box: ResizeObserverBoxOptions,
  ) {}
}

// Import the core angular services.
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  input,
  NgZone,
  OnDestroy,
  OnInit,
  output,
  Renderer2,
  signal,
  viewChild,
} from '@angular/core';

import { ApplicationConfiguration } from '@administration/shared/models/application-configuration.interface';
import { RESIZE_OPTION_BOX } from '@core/observers/resize-observer/tokens/resize-option-box';
import { ResizeObserverDirective } from '@core/observers/resize-observer/directives/resize-observer.directive';
import { ResizeObserverService } from '@core/observers/resize-observer/services/resize-observer.service';
import { ScrollerState } from '@core/scroller/scroller-state';
import { TessSignalBusService } from '@core/services/tess-signal-bus.service';

// ----------------------------------------------------------------------------------- //
// ----------------------------------------------------------------------------------- //

// CAUTION: Not all browsers support "passive" event bindings. This can be done more
// gracefully with some feature-checks. But, for the sake of simplicity (since a
// scrolling demo is already quite complex), I'm only going to support browsers that
// support passive event bindings.
const PASSIVE = {
  passive: true,
};

@Component({
  standalone: true,
  selector: 'tess-scroller',
  exportAs: 'tessScroller',
  templateUrl: './scroller.component.html',
  styleUrls: ['./scroller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ResizeObserverDirective],
  providers: [
    ResizeObserverService,
    {
      provide: RESIZE_OPTION_BOX,
      useValue: 'border-box',
    },
  ],
  host: {
    '[style.--scrollbar-box-shadow]': 'scrollbarBoxShadow()',
  },
})
export class ScrollerComponent implements OnInit, AfterViewInit, OnDestroy {
  // @Input() changeDisabled: Subject<boolean> = new Subject();
  // Classes to be added to the viewport.

  readonly cssClassList = input<string>('');

  readonly state = output<ScrollerState>();

  readonly #elementRef = inject(ElementRef);
  readonly #renderer = inject(Renderer2);
  readonly #tessSignalBusService = inject(TessSignalBusService);
  readonly #zone = inject(NgZone);

  // DOM-Reference.
  readonly contentRefSignal = viewChild.required<ElementRef>('contentRef');
  readonly scrollbarTrackRefSignal = viewChild.required<ElementRef>('scrollbarTrackRef');
  readonly scrollbarThumbRefSignal = viewChild.required<ElementRef>('scrollbarThumbRef');
  readonly viewportRefSignal = viewChild.required<ElementRef>('viewportRef');

  readonly scrollbarBoxShadow = signal<string | undefined>(undefined);

  // TODO: implement.
  // readonly #destroyRef = inject(DestroyRef);

  #boxShadow: string;
  #contentHeight = 0;
  #disabled = false;
  #draggingStateViewportBottom = 0;
  #draggingStateViewportHeight = 0;
  #draggingStateViewportTop = 0;
  #scrollbarTrackHeight = 0;
  #scrollbarThumbHeight = 0;
  #scrollHeight = 0;
  #scrollPercentage = 0;
  #scrollTop = 0;
  #viewportHeight = 0;

  // ---
  // PUBLIC METHODS.
  // ---

  ngOnInit(): void {
    // Keep track of the box-shadow that was set initially from outside, if it was set.
    this.#boxShadow = getComputedStyle(this.#elementRef.nativeElement).getPropertyValue('--scrollbar-box-shadow');
  }

  // I get called once after the view and its contents have been initialized.
  ngAfterViewInit(): void {
    // Transition to our initial, passive state to listen from scrolling.
    this.#passiveStateSetup();

    // this.changeDisabled
    //   .pipe(
    //     distinctUntilChanged(),
    //     debounceTime(100),
    //     takeUntilDestroyed(this.#destroyRef)
    //   )
    //   .subscribe((disabled: boolean) => {
    //     this._disabled = disabled;
    //     this._renderer.setStyle(
    //       this.viewportRef.nativeElement,
    //       'overflow-y',
    //       this._disabled ? 'hidden' : 'scroll'
    //     );

    //     // Show or hide the scrollbar track depending on whether a scrollbar is present or not.
    //     this._renderer.setStyle(
    //       this.scrollbarTrackRef.nativeElement,
    //       'display',
    //       this._scrollbarThumbHeight === 0 || this._disabled ? `none` : 'block'
    //     );

    //     this.updateState();
    //   });
  }

  // I get called once when the component is being unmounted.
  ngOnDestroy(): void {
    // I don't know what state we're in, so just destroy "all the things!"
    this.#passiveStateTeardown();
    this.#pagingStateTeardown();
    this.#draggingStateTeardown();
  }

  onResize(_: ResizeObserverEntry[]): void {
    const refreshed = this.#calculateDimensions();
    // There were no changes to the scroller.
    if (refreshed) {
      this.#passiveStateHandleViewportScroll(null);
    }
  }

  // ---
  // PRIVATE METHODS.
  // ---

  // I calculate and store the scroll state of the content within the viewport. These
  // values are then used in subsequent calculations.
  // --
  // CAUTION: This method gets CALLED A LOT - it's execution needs to be super fast!
  #calculateViewportScrollPercentage(): void {
    // Get short-hand references to the native DOM elements.
    const viewportElement = this.viewportRefSignal().nativeElement;

    if (this.#scrollHeight) {
      this.#scrollTop = viewportElement.scrollTop;
      this.#scrollPercentage = this.#scrollTop / this.#scrollHeight;
    } else {
      this.#scrollTop = 0;
      this.#scrollPercentage = 0;
    }
  }

  // I return a value that is constrained by the given min and max values.
  #clamp(value: number, minValue: number, maxValue: number): number {
    return Math.min(Math.max(value, minValue), maxValue);
  }

  // I handle mousemove events for the dragging-state.
  // --
  // CAUTION: Using FAT-ARROW FUNCTION to generate bound instance method.
  #draggingStateHandleMousemove = (event: MouseEvent): void => {
    // Get short-hand references to the native DOM elements.
    const viewportElement = this.viewportRefSignal().nativeElement;

    // Calculate the location of the mouse within the smaller, "meaningful viewport"
    // that was determined at the beginning of the dragging-state. We'll need to then
    // take this smaller value and translate it onto the larger, true viewport.
    const clientY = this.#clamp(event.clientY, this.#draggingStateViewportTop, this.#draggingStateViewportBottom);
    const localOffset = clientY - this.#draggingStateViewportTop;
    const localOffsetPercentage = localOffset / this.#draggingStateViewportHeight;

    // Scroll the viewport to the calculated location and then update the thumb to
    // match the viewport's state.
    viewportElement.scrollTop = localOffsetPercentage * this.#scrollHeight;
    this.#calculateViewportScrollPercentage();
    this.#updateThumbPositionToMatchScrollPercentage();
  };

  // I handle mouseup events for the dragging-state.
  // --
  // CAUTION: Using FAT-ARROW FUNCTION to generate bound instance method.
  #draggingStateHandleMouseup = (): void => {
    // Transition to the passive state.
    this.#draggingStateTeardown();
    this.#passiveStateSetup();
  };

  // I setup the dragging-state, initializing state values and binding all state-
  // specific event-handlers. The dragging-state moves the simulated scrollbar thumb
  // alongside the user's mouse, and then updates the viewport offset to match the
  // simulated scrollbar state.
  #draggingStateSetup(event: MouseEvent): void {
    // Get short-hand references to the native DOM elements.
    const viewportElement = this.viewportRefSignal().nativeElement;
    const scrollbarElement = this.scrollbarTrackRefSignal().nativeElement;
    const scrollbarThumbElement = this.scrollbarThumbRefSignal().nativeElement;

    // When the user clicks on the scrollbar-thumb, we need to use the mouse LOCATION
    // and the scrollbar-thumb SIZE to translate the viewport into a SLIGHTLY SMALLER
    // viewport. The reason for this is that we want the thumb's location to mirror
    // the location of the mouse. TODO this, we're going to need to get the rendered
    // location of the viewport and the scrollbar thumb.
    const viewportRect = viewportElement.getBoundingClientRect();
    const scrollbarThumbRect = scrollbarThumbElement.getBoundingClientRect();

    // Figure out how the initial mouse location splits the thumb element in half.
    const initialY = event.clientY;
    const thumbLocalY = initialY - scrollbarThumbRect.top;

    // Now, reduce the "meaningful viewport" dimensions by the top-half and the
    // bottom-half of the thumb. This way, the viewport will be fully-scrolled when
    // the bottom of the thumb hits the bottom of scrollbar, even if the user's mouse
    // hasn't fully-reached the bottom of the viewport.
    this.#draggingStateViewportTop = viewportRect.top + thumbLocalY;
    this.#draggingStateViewportBottom = viewportRect.bottom - scrollbarThumbRect.height + thumbLocalY;
    this.#draggingStateViewportHeight = this.#draggingStateViewportBottom - this.#draggingStateViewportTop;

    // Always show the scrollbar while dragging, even if the user's mouse leaves the
    // surface area of the viewport.
    scrollbarElement.classList.add('scrollbar--dragging');

    window.addEventListener('mousemove', this.#draggingStateHandleMousemove);
    window.addEventListener('mouseup', this.#draggingStateHandleMouseup);
  }

  // I teardown the dragging-state, removing all state-specific event-handlers.
  #draggingStateTeardown(): void {
    // Get short-hand references to the native DOM elements.
    const scrollbarElement = this.scrollbarTrackRefSignal().nativeElement;

    scrollbarElement.classList.remove('scrollbar--dragging');

    window.removeEventListener('mousemove', this.#draggingStateHandleMousemove);
    window.removeEventListener('mouseup', this.#draggingStateHandleMouseup);
  }

  // I setup the paging-state, initializing state values and binding all state-
  // specific event-handlers. The paging-state adjusts the viewport scroll offset by
  // one page, either up or down, in the direction of the mouse.
  #pagingStateSetup(event: MouseEvent): void {
    // Get short-hand references to the native DOM elements.
    const viewportElement = this.viewportRefSignal().nativeElement;
    const scrollbarThumbElement = this.scrollbarThumbRefSignal().nativeElement;

    // Get the viewport coordinates of the scrollbar thumb - we need to see if the
    // user clicked ABOVE the thumb or BELOW the thumb.
    const scrollbarThumbRect = scrollbarThumbElement.getBoundingClientRect();

    // Scroll content UP by ONE PAGE.
    if (event.clientY < scrollbarThumbRect.top) {
      viewportElement.scrollTop = Math.max(0, this.#scrollTop - this.#viewportHeight);

      // Scroll content DOWN by ONE PAGE.
    } else {
      viewportElement.scrollTop = Math.min(this.#scrollHeight, this.#scrollTop + this.#viewportHeight);
    }

    this.#calculateViewportScrollPercentage();
    this.#updateThumbPositionToMatchScrollPercentage();

    // Transition to the passive state.
    // --
    // TODO: In the future, we could set a timer to see if the user holds-down the
    // mouse button, at which point we could continue to page the viewport towards
    // the mouse cursor. However, for this exploration, we're going to stick to a
    // single paging per mouse event.
    this.#pagingStateTeardown();
    this.#passiveStateSetup();
  }

  // I teardown the paging-state, removing all state-specific event-handlers.
  #pagingStateTeardown(): void {
    // Nothing to teardown for this state.
  }

  // I handle mousedown events for the passive-state.
  // --
  // CAUTION: Using FAT-ARROW FUNCTION to generate bound instance method.
  #passiveStateHandleScrollbarMousedown = (event: MouseEvent): void => {
    // Get short-hand references to the native DOM elements.
    const scrollbarThumbElement = this.scrollbarThumbRefSignal().nativeElement;

    // In order to prevent the user's click-and-drag gesture from highlighting a
    // bunch of text on the page, we have to prevent the default behavior of the
    // mousedown event.
    event.preventDefault();

    if (event.target === scrollbarThumbElement) {
      // Transition to the dragging state (the user is going to drag the thumb to
      // adjust scroll-offset of the viewport).
      this.#passiveStateTeardown();
      this.#draggingStateSetup(event);
    } else {
      // Transition to the paging state (the user is going to adjust the scroll-
      // offset of the viewport by increments of the viewport height).
      this.#passiveStateTeardown();
      this.#pagingStateSetup(event);
    }
  };

  #calculateDimensions(): boolean {
    // A recalculation is not needed since there were no changes to the scroller.
    if (
      this.#viewportHeight === this.viewportRefSignal().nativeElement.clientHeight &&
      this.#contentHeight === this.viewportRefSignal().nativeElement.scrollHeight
    ) {
      return false;
    }

    // Calculate the dimensions of the viewport and other elements
    // once at the beggining, and every time there is a resize.
    // As we react to events scroll-percentages also get calculated.
    this.#viewportHeight = this.viewportRefSignal().nativeElement.clientHeight;
    this.#contentHeight = this.viewportRefSignal().nativeElement.scrollHeight;
    this.#scrollHeight = this.#contentHeight - this.#viewportHeight;

    this.#scrollbarThumbHeight =
      this.#viewportHeight < this.#contentHeight
        ? (this.#viewportHeight * this.#viewportHeight) / this.#contentHeight
        : 0;
    // Set the height of the thumb.
    this.#renderer.setStyle(this.scrollbarThumbRefSignal().nativeElement, 'height', `${this.#scrollbarThumbHeight}px`);

    // Show or hide the scrollbar track depending on whether a scrollbar is present or not.
    this.#renderer.setStyle(
      this.scrollbarTrackRefSignal().nativeElement,
      'display',
      this.#scrollbarThumbHeight === 0 || this.#disabled ? `none` : 'block',
    );
    // Update the scrollbar track height after the track was either hidden or shown.
    this.#scrollbarTrackHeight = this.scrollbarTrackRefSignal().nativeElement.clientHeight;

    // Show or remove the box-shadow if one was set.
    if (this.#boxShadow) {
      this.scrollbarBoxShadow.set(this.#scrollbarThumbHeight === 0 ? 'none !important' : this.#boxShadow);
    }

    this.state.emit(new ScrollerState(this.#scrollbarThumbHeight, this.#disabled));

    this.#calculateViewportScrollPercentage();
    return true;
  }

  // I handle scroll events for the passive-state.
  // --
  // CAUTION: Using FAT-ARROW FUNCTION to generate bound instance method.
  #passiveStateHandleViewportScroll = (_: MouseEvent): void => {
    this.#calculateViewportScrollPercentage();
    this.#updateThumbPositionToMatchScrollPercentage();
  };

  // I setup the passive-state, initializing state values and binding all state-
  // specific event-handlers. The passive-state primarily listens for scroll events on
  // the viewport and then updates the simulated scrollbar to match the location.
  #passiveStateSetup(): void {
    this.#calculateDimensions();

    // Since these are the initial state's event handlers, it should create a
    // cascading effect wherein every subsequent event handler is bound outside of
    // the Angular Zone. This should prevent any of the event handlers contained
    // within this component from triggering change-detection in the Angular app.
    this.#zone.runOutsideAngular(() => {
      this.viewportRefSignal().nativeElement.addEventListener(
        'scroll',
        this.#passiveStateHandleViewportScroll,
        PASSIVE,
      );
      this.scrollbarTrackRefSignal().nativeElement.addEventListener(
        'mousedown',
        this.#passiveStateHandleScrollbarMousedown,
      );
    });
  }

  // I teardown the passive-state, removing all state-specific event-handlers.
  #passiveStateTeardown(): void {
    this.viewportRefSignal().nativeElement.removeEventListener(
      'scroll',
      this.#passiveStateHandleViewportScroll,
      PASSIVE,
    );
    this.scrollbarTrackRefSignal().nativeElement.removeEventListener(
      'mousedown',
      this.#passiveStateHandleScrollbarMousedown,
    );
  }

  // I update the offset of the simulated scrollbar thumb to match the offset of the
  // content within the viewport element.
  #updateThumbPositionToMatchScrollPercentage(): void {
    this.#tessSignalBusService.set(ApplicationConfiguration.CACHE_KEY_SCROLLER, new Date().getTime());
    // Get short-hand references to the native DOM elements.
    const scrollbarThumbElement = this.scrollbarThumbRefSignal().nativeElement;

    const offset = (this.#scrollbarTrackHeight - this.#scrollbarThumbHeight) * this.#scrollPercentage;
    scrollbarThumbElement.style.transform = `translateY( ${offset}px )`;
  }
}

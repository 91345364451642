<div #viewportRef (tessResizeObserver)="onResize($event)" [class]="['viewport', cssClassList()]">
  <!-- The Viewport will have a scrollbar, which will change the width of the
  content. As such, we have to wrap the content in an element that has an explicit
  width that won't be affected by the scrollbar. -->
  <div #contentRef (tessResizeObserver)="onResize($event)" class="content">
    <ng-content />
  </div>
</div>

<!-- The scrollbar will be "stacked" over the viewport. -->
<div #scrollbarTrackRef class="scrollbar">
  <div #scrollbarThumbRef class="scrollbar__thumb">
    <!--  -->
  </div>
</div>

import { ElementRef, Inject, Injectable, NgZone } from '@angular/core';

import { Observable, share } from 'rxjs';

import { RESIZE_OBSERVER_SUPPORT } from '@core/observers/resize-observer/tokens/support';
import { RESIZE_OPTION_BOX } from '@core/observers/resize-observer/tokens/resize-option-box';

// https://github.com/taiga-family/ng-web-apis/
@Injectable({ providedIn: 'root' })
export class ResizeObserverService extends Observable<readonly ResizeObserverEntry[]> {
  constructor(
    @Inject(ElementRef) { nativeElement }: ElementRef<Element>,
    @Inject(NgZone) ngZone: NgZone,
    @Inject(RESIZE_OBSERVER_SUPPORT) support: boolean,
    @Inject(RESIZE_OPTION_BOX) box: ResizeObserverBoxOptions,
  ) {
    let observer: ResizeObserver;

    super(subscriber => {
      if (!support) {
        subscriber.error('ResizeObserver is not supported in your browser');

        return null;
      }

      observer = new ResizeObserver(entries => {
        ngZone.run(() => {
          subscriber.next(entries);
        });
      });
      observer.observe(nativeElement, { box });

      return () => {
        observer.disconnect();
      };
    });

    return this.pipe(share());
  }
}

import { Google } from '@administration/shared/models/application-configuration-google.interface';
import { IBranding } from '@administration/shared/models/application-branding.interface';

export interface IConfiguration {
  Branding: IBranding;
  ClientId: string;

  CRMApplicationVersion: string;
  ClientPortalApplicationVersion: string;

  ApiServiceBaseUri: string;
  DesignerApiServiceBaseUri: string;
  DesignerAppBaseUri: string;
  ReportingApiServiceBaseUri: string;

  DocumentationURL: string;
  MarketingURL: string;
  Google: Google;

  TimeZone?: string;
}

// TODO: merge the two.
export class ApplicationConfiguration {
  static readonly CACHE_KEY_RESIZE_OBSERVER: string = 'CACHE_KEY_RESIZE_OBSERVER';
  static readonly CACHE_KEY_SCROLLER: string = 'CACHE_KEY_SCROLLER';
  static readonly CACHE_KEY_TOGGLE_NAVIGATION: string = 'CACHE_KEY_TOGGLE_NAVIGATION';
}
